import { inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ReportControllerService } from "../../../api/v1";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { distinctUntilChanged, map, of, shareReplay, switchMap } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ReportsService {
    reportApi = inject(ReportControllerService);
    auth = inject(OidcSecurityService);
    reports$ = this.auth.isAuthenticated$.pipe(
        map((ev) => ev.isAuthenticated),
        distinctUntilChanged(),
        switchMap((logged) => {
            if (!logged) return of([]);
            else return this.reportApi.getReports();
        }),
        shareReplay()
    );
    reports = toSignal(this.reports$);
}
